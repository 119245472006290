//navigation hide/show
$(document).on("click", ".menu-icon", function () {
    var menuicon = $(this);
    $(this).toggleClass("active");
    $(document).find("nav.navbar").toggleClass("active");

    $("body").off("click").on("click", function (e) {
        var target = $(e.target);

        if (!target.is(menuicon) && !target.is($(document).find("nav.navbar, nav.navbar *"))) {
            menuicon.removeClass("active");
            $(document).find("nav.navbar").removeClass("active");
        }

    });

});

$(document).on("click", ".nav.navbar-nav > li > a", function(){

    var a = $(this);

    a.parent("li.menuItem").removeClass("open");
    
    if(!a.parent("li.menuItem").hasClass("dropdown")){
        
        $(document).find(".menu-icon.active").trigger("click");
        $(".nav.navbar-nav > li > a").removeClass("active-drop");
        $(".nav.navbar-nav > li").removeClass("open-dropdown");
        $(".nav.navbar-nav > li span.fa-chevron-right").removeClass("fa-chevron-down");

    } else {
        a.parent("li.dropdown").toggleClass("open-dropdown");
        a.parent("li.dropdown").siblings("li.dropdown").removeClass("open-dropdown");

        a.children("span.fa-chevron-right").toggleClass("fa-chevron-down");
        a.parent("li.dropdown").siblings("li.dropdown").find("span.fa-chevron-right").removeClass("fa-chevron-down");
        
        if(a.parent("li.dropdown").children().length === 1){
            $(document).find(".menu-icon.active").trigger("click");
        }
    }

});

$(document).on("click", ".nav.navbar-nav > li.dropdown > .dropdown-menu a", function(){

    var subA = $(this);

    //subA.parents(".dropdown-menu").prev("a").addClass("active-drop");
    $(document).find(".menu-icon.active").trigger("click");

}); 

$(document).on("click", ".logo > a, a.calendar-link", function(){
    $(".nav.navbar-nav > li").removeClass("open-dropdown");
    $(".nav.navbar-nav > li span.fa-chevron-right").removeClass("fa-chevron-down");
});

$(document).on("click", ".dropdown-custom-toggle", function(){
    var dropdown = $(this);
    $("body").off("click").on("click", function (e) {
        var target = $(e.target);

        if (!target.is(dropdown)) {
            dropdown.parent(".dropdown").removeClass("open");
        }

    });
});